<template>
	<div class="header">
		<header class="head">
			<div class="center">
				<div class="head_main">
					<a href="/" class="logo"><img src="../../assets/images/head_logo.png" /></a>
					<ul class="right">
						<li class="item"><img class="icon" src="../../assets/images/head_icon1.png" />一件代发</li>
						<li class="item"><img class="icon" src="../../assets/images/head_icon2.png" />极速发货</li>
						<li class="item"><img class="icon" src="../../assets/images/head_icon3.png" />15天退换货</li>
					</ul>
				</div>
			</div>
		</header>
		<div class="fixed_menu">
			<div class="carts" @click="$router.push('./cart')"><i class="iconfont icon-cart"></i>进货车
			<!-- <span class="num">5</span> -->
			</div>
			<div class="wechat" @mouseover="is_wxcode = true" @mouseout="is_wxcode = false">
				<i class="iconfont icon-weixin"></i>
				<div :class="is_wxcode?'code_show active':'code_show'" class="code_show">
					<img class="code" src="../../assets/images/code.png" />
					<p class="txt">扫码关注公众号~</p>
				</div>
			</div>
			<el-backtop :bottom="100" class="backtop" :visibility-height="500">
				<i class="iconfont icon-top"></i>
			</el-backtop>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
export default {
	name: "myhead",
	data() {
		return {
			is_wxcode: false,
		};
	},
}
</script>

<style scoped>
.header{width: 100%;height: 98px;position: relative;min-width: 1200px;}
.header .head{width: 100%;height: 98px;background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#000000, #000000);background-blend-mode: normal, normal;position: fixed;top: 0;left: 0;z-index: 99;}
.header .head_main{width: 100%;height: 100%;display: flex;justify-content: space-between;align-items: center;}
.header .head_main .logo{display: block;width: 315px;}
.header .head_main .right{display: flex;font-size: 16px;color: #fefefe;}
.header .head_main .right .item{margin-left: 30px;display: flex;align-items: center;}
.header .head_main .right .item .icon{width: 20px;height: 20px;margin-right: 10px;}

.fixed_menu{width: 32px;height: auto;position: fixed;right: 6px;top: 50%;transform: translateY(-50%);z-index: 99;transition: all .5s;}
.fixed_menu .carts{width: 32px;height: auto;padding: 10px 0;background: #e4393c;font-size: 12px;color: #fff;display: flex;justify-content: center;align-items: center;writing-mode: vertical-lr;cursor: pointer;}
.fixed_menu .carts .icon-cart{font-size: 16px;color: #fff;margin-bottom: 8px;}
.fixed_menu .carts .num{width: 16px;height: 16px;border-radius: 50%;background: #fff;display: flex;justify-content: center;align-items: center;font-size: 12px;color: #e4393c;margin-top: 6px;writing-mode: initial;}
.fixed_menu .wechat{width: 32px;height: 42px;background: #e4393c;margin-top: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;position: relative;}
.fixed_menu .wechat .icon-weixin{font-size: 18px;color: #fff;}
.fixed_menu .wechat .code_show{width: 160px;height: auto;background: #fff;border-radius: 8px;box-sizing: border-box;padding: 10px;text-align: center;position: absolute;top: 50%;transform: translate(10%,-50%);right: 50px;z-index: 22;filter: drop-shadow(0 0 5px #eee);visibility: hidden;opacity: 0;transition: all .2s ease-in-out;}
.fixed_menu .wechat .code_show.active{transform: translate(0,-50%);visibility: visible;opacity: 1;}
.fixed_menu .wechat .code_show::before{content: '';width: 10px;height: 10px;background: #fff;position: absolute;right: -6px;top: 50%;transform: translateY(-50%) rotate(45deg);}
.fixed_menu .wechat .code_show .code{width: 140px;height: 140px;}
.fixed_menu .wechat .code_show .txt{line-height: 1;margin-top: 4px;font-size: 12px;color: #666;text-align: center;}



.fixed_menu .backtop{width: 32px;height: 34px;background: #e4393c;margin-top: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;position: initial;border-radius: initial;box-shadow: initial;}
.fixed_menu .backtop .icon-top{font-size: 18px;color: #fff;}

</style>