<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="order_input">
					<p>收货地址</p>
					<div class="input_wrap">
						<div class="con3">
							<p class="title">收货人</p>
							<el-input placeholder="请输入收货人姓名" v-model="address.name" @blur="postEditAddress()"></el-input>
						</div>
						<div class="con3">
							<p class="title">手机号码</p>
							<el-input placeholder="请输入收货人手机号" v-model="address.phone" @blur="postEditAddress()"></el-input>
						</div>
						<div class="con3">
							<p class="title">省市区</p>
							<el-cascader
								size="large"
								:options="regionData"
								v-model="selectedOptions"
								:props="regionProps"
								@change="areaChange">
							</el-cascader>
						</div>
						<div class="con1">
							<p class="title">详细地址</p>
							<el-input placeholder="请输入详细地址" v-model="address.detail" @blur="postEditAddress()"></el-input>
						</div>
					</div>
				</div>
				<div class="order_goods">
					<p>商品信息</p>
					<div class="table_wrap">
						<el-table :data="list" v-if="list.length > 0">
							<el-table-column label="货品信息" width="" prop="scope">
								<template slot-scope="scope">
									<div class="goods_wrap">
										<div class="img_box"><img class="coverimg" :src="scope.row.sku?scope.row.sku[0].pic:''" /></div>
										<div class="con_box">
											<p class="name">{{scope.row.name}}</p>
											<p class="color">{{scope.row.sku?scope.row.sku[0].spec_value:''}}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="数量" prop="scope" width="200">
								<template slot-scope="scope">
									<el-input-number class="input_number" v-if="scope.row.sku"
									v-model="item_num" @change="handleChange" 
									:min="1" :max="scope.row.sku?scope.row.sku[0].stock_num:1" label="改变数量">
									</el-input-number>
								</template>
							</el-table-column>
							<el-table-column label="发布价" prop="scope" width="200">
								<template slot-scope="scope">
									{{scope.row.sku?scope.row.sku[0].price:0}}
								</template>
							</el-table-column>
							<el-table-column label="商品总价" prop="scope" width="200">
								<template slot-scope="scope">
									{{checkout_data.order_amount}}
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="total_txt">
						<div class="left">
							<p>给卖家留言：</p>
							<el-input placeholder="可以告诉卖家您的特殊要求，需先和卖家协商一致" v-model="buyer_words"></el-input>
						</div>
						<!-- <div class="right">
							<p>商品总价运费: ￥{{checkout_data.post_amount}} <span> 应付:<em>￥{{checkout_data.pay_amount}}</em></span></p>
							<p>(实付:￥{{checkout_data.pay_amount}})</p>
						</div> -->
					</div>
				</div>
				<div class="order_pay">
					<p>支付方式</p>
					<div class="pay_way">
						<p class="pay_tab" :class="pay_type==1?'active':''" @click="selectPayType(1)"><img src="../../assets/images/wechatpay.png" /></p>
						<p class="pay_tab" :class="pay_type==2?'active':''" @click="selectPayType(2)"><img src="../../assets/images/alipay.png" /></p>
					</div>
				</div>
				
			</div>
		</div>
		<div class="fixed_wrap">
			<div class="order_total">
				<div class="left">
					<p class="t1">总计</p>
					<p class="t2">货品种类：1种，数量总计：{{item_num}}件</p>
				</div>
				<div class="right">
					<p>货品总金额：<em>{{checkout_data.order_amount}}</em>元</p>
					<p>运费共计：<em>{{checkout_data.post_amount}}</em>元<span v-if="checkout_data.procedures_money>0">，手续费：<em>{{checkout_data.procedures_money}}元</em></span></p>
				</div>
			</div>
			<div class="fixed_inner">
				<p class="total">应付总额（含运费）：<em>{{checkout_data.pay_amount}}</em>元</p>
				<p class="submit_btn" @click="subCreate()">提交订单</p>
			</div>
		</div>
		<!-- 订单支付弹窗 -->
		<orderPay @close="closePay()" @success="dialog.payDialog = false" :show.sync="dialog.payDialog" :order_id.sync="order_id"></orderPay>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import orderPay from '../../components/orderPay/orderPay';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "settlement",
	data() {
		return {
			list:[],
			regionData: [],
			regionProps:{
				value:'id',
				label:'label'
			},
			dialog:{
				payDialog:false
			},
			selectedOptions: [],
			address:{
				province_id:'',
				city_id:'',
				town_id:'',
				street_id:'',
				detail:'',
				name:'',
				phone:'',
			},
			is_loading:false,
			checkout_data:"",
			product_id: 0,
			product_sku_id: 0,
			item_num:1,
			total_price:0,
			buyer_words:'',
			pay_type:'',
			order_id: 0
		}
	},
	components:{
		myHead,orderPay
	},
	created(){
		this.product_id = this.$route.query.product_id
		this.product_sku_id = this.$route.query.product_sku_id
		this.initRegionData()
		this.initCheckOut()
	},
	methods: {
		...mapActions({
			getRegionData:"order/getRegionData",
			getCheckout:"order/getCheckout",
			opEditAddress:"order/opEditAddress",
			opCreate:"order/opCreate",
		}),
		selectPayType(type){
			this.pay_type = type
		},
		handleChange(value) {
			this.initCheckOut()
		},
		calculate(){
			var checkout_data = this.checkout_data
			var total_price = scope.row.sku[0].price * this.item_num
			this.total_price = total_price.toFixed(2)
		},
		areaChange(value) {
			console.log(value)
			this.address.province_id = value[0]
			this.address.city_id = value[1]
			this.address.town_id = value[2]
			this.postEditAddress()
		},
		//编辑收货地址
		postEditAddress(){
			var params = this.address
			this.opEditAddress({data:params,success:(res)=>{
				// console.log(res)
			}})
		},
		initRegionData(){
			var params = {product_id:this.product_id,product_sku_id:this.product_sku_id,item_num:this.item_num}
			this.getRegionData({data:params,success:(res)=>{
				if(res.code===200){
					this.regionData = res.data
					
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		initCheckOut(){
			var params = {product_id:this.product_id,product_sku_id:this.product_sku_id,item_num:this.item_num}
			this.getCheckout({data:params,success:(res)=>{
					console.log(res)
					if(res.code == 200){
						var address = res.data.address
						if(address.id){
							this.address = address
							this.selectedOptions[0] = parseInt(address.province_id)
							this.selectedOptions[1] = parseInt(address.city_id)
							this.selectedOptions[2] = parseInt(address.town_id)
						}
						this.checkout_data = res.data
						this.list = [res.data.product]
					}
				}
			})
		},
		//提交订单
		subCreate(){
			if(this.is_loading) return
			this.is_loading = true
			var params = {product_id:this.product_id,product_sku_id:this.product_sku_id,item_num:this.item_num,pay_type:this.pay_type,buyer_words:this.buyer_words,order_id:this.order_id}
			this.opCreate({data:params,success:(res)=>{
					this.is_loading = false
					if(res.code == 200){
						this.order_id = Number(res.data.order_id)
						this.dialog.payDialog = true
					}else{
						this.$message({message: res.msg,type: 'error'});
					}
				}
			})
		},
		closePay(){//关闭支付
		this.$message({message: '支付取消！',type: 'error'});
			setTimeout(()=>{
				this.$router.replace('./uc_order?status=1')
			},1000)
		}
		
	}
	
}
</script>

<style scoped>
.wrap .wrap_body{padding-bottom: 0;}
.fixed_wrap{width: 1200px;height: auto;margin: 0 auto;position: fixed;left: 50%;transform: translateX(-50%);bottom: 0;z-index: 99;}
.fixed_wrap .fixed_inner{width: 1200px;height: 60px;background: #fff;box-shadow: 0px -1px 1px 0px #eeeeee;display: flex;justify-content: flex-end;align-items: center;}
.fixed_wrap .fixed_inner .total{font-size: 14px;color: #666666;margin-right: 40px;}
.fixed_wrap .fixed_inner .total>em{font-size: 16px;color: #fa5757;font-weight: bold;}
.fixed_wrap .fixed_inner .submit_btn{width: 180px;height: 100%;display: flex;justify-content: center;align-items: center;background: var(--red);color: #fff;font-size: 16px;font-weight: bold;cursor: pointer;}
.order_input{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 28px 40px 34px;background: #fff;line-height: 1;}
.order_input .input_wrap{padding-top: 26px;display: flex;flex-wrap: wrap;}
.order_input .input_wrap .con1{margin-top: 20px;width: 910px;}
.order_input .input_wrap .con3{width: 290px;margin-right: 20px}
.order_input .input_wrap .title{font-size: 14px;color: #666666;margin-bottom: 10px;}
.order_input .input_wrap .title::before{content: '*';color: #f0413e;}
.order_goods{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.order_goods .table_wrap{margin-top: 30px;font-size: 12px;}
.goods_wrap{width: 100%;box-sizing: border-box;display: flex;text-align: left;}
.goods_wrap .img_box{width: 48px;height: 48px;overflow: hidden;background: #eee;}
.goods_wrap .img_box>img{width: 100%;height: 100%;object-fit: cover;}
.goods_wrap .con_box{width: 290px;margin-left: 10px;}
.goods_wrap .con_box .name{line-height: 16px;color: #333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.goods_wrap .con_box .color{color: #999999;margin-top: 6px;line-height: 1;}
.order_goods .total_txt{display: flex;justify-content: space-between;align-items: center;font-size: 12px;padding: 26px 0 15px;}
.order_goods .total_txt .left{display: flex;align-items: center;width: 450px;white-space: nowrap;}
.order_goods .total_txt .right{text-align: right;color: #999;}
.order_goods .total_txt .right span{font-size: 16px;color: #333;}
.order_goods .total_txt .right span>em{color: var(--red);font-weight: bold;}
.order_goods .total_txt .right>p:nth-child(2){margin-top: 10px;}
.order_pay{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px 240px;background: #fff;line-height: 1;}
.order_pay .pay_way{margin-top: 24px;display: flex;}
.order_pay .pay_way .pay_tab{width: 150px;height: 46px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;justify-content: center;align-items: center;margin-right: 20px;cursor: pointer;}
.order_pay .pay_way .pay_tab.active{border-color: var(--red);}
.order_pay .pay_way .pay_tab img{width: auto;height: 24px;}
.order_total{margin-top: 20px;width: 100%;height: auto;box-sizing: border-box;padding: 25px 20px 20px;background: #fffbec;line-height: 1;border: 1px solid #e6daae;display: flex;justify-content: space-between;color: #3f3b3a;align-items: flex-start;}
.order_total .left{display: flex;align-items: center;}
.order_total .left .t1{font-size: 14px;margin-right: 40px;font-weight: bold;}
.order_total .left .t2{font-size: 12px;}
.order_total .right{text-align: right;font-size: 12px;}
.order_total .right p>em{font-weight: bold;}
.order_total .right p:nth-child(2){margin-top: 6px;}
</style>
<style>

.el-cascader{width: 100%;}
.el-table .el-table_1_column_2 .cell .num{color: #999;}
.el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
.el-table .el-table__cell{vertical-align: top;padding: 24px 0;}
.el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
.el-table th.el-table__cell>.cell{padding: 0 20px;text-align: center;}
.el-table th.el-table_1_column_1 .cell{text-align: left;}
.el-table .el-table_1_column_2 .cell{padding: 0;}
.input_number{width: 100%;line-height: 30px;}
.input_number .el-input__inner{padding: 0 30px !important;line-height: 32px;height: 32px}
.el-input-number__decrease, .el-input-number__increase{width: 30px;}
.con_style{color: #333;}
.la_style{color: #999;width: 70px;}
.el-descriptions{font-size: 12px;}
.el-descriptions__title{color: #333;}
.el-input-number__decrease:hover, .el-input-number__increase:hover ,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.input_number .el-input__inner:hover ,.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) ,.el-input__inner:focus ,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}


</style>