<template>
	<div>
		<el-dialog :close-on-click-modal="false" :title="tip" @close="cancel()" :visible.sync="dialog.payDialog" width="20%" center >
			<div class="opts_wrap" v-loading="loading">
				<div class="opts_line">
					<el-image class="qr_code" :src="qr_code"></el-image>
				</div>
				<div class="btn_wrap">
					<!-- <p class="btn">复制</p> -->
					<!-- <p class="btn" @click="cancel()">关闭</p> -->
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderPay",
	props: {
		show: {
			type: Boolean,
			default: false
		},
		order_id: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			dialog: {
				payDialog:false,
			},
			loading: false,
			qr_code:'',
			tip:'',
		};
	},
	
	created(){
	},
	mounted() {
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.init()
			}
		}
	},
	destroyed() {
	},
	methods: {
		...mapActions({
			opPayOrder:"order/opPayOrder",
			getCheckPayState:"order/getCheckPayState",
		}),
		init(){
			if(this.loading || !this.order_id) return
			this.loading = true
			var param = {order_id:this.order_id}
			this.opPayOrder({data:param,success:(res)=>{
				if(res.code===200){
					this.address = res.data
					this.dialog.payDialog = true
					this.qr_code = res.data.qr_code_url
					this.tip = res.data.tip
					this.checkPayState()
				} else {
					this.$message({message: res.msg,type: 'error',})
					this.$emit('close', {})
				}
				this.loading = false
			}})
		},
		//提交支付获取付款二维码
		checkPayState(){
			let that = this
			if(!that.dialog.payDialog) return
			setTimeout(function(){
				that.getCheckPayState({data:{order_id:that.order_id},success:(res) => {
					if(res.data == 1){
						that.$message({message: '支付成功',type: 'success',onClose:()=>{
							that.$router.push('./uc_order?status=2')
							that.$emit('success', {})
						}})
					}else{
						that.checkPayState()
					}
				}})
			},2000)
			
			
		},
		cancel(){
			this.$emit('close', {})
			this.dialog.payDialog = false
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
	.opts_line .txt{width: 100%;font-size: 16px;display: flex;}
	.opts_line .qr_code{width: 250px;height: auto;margin: 0 auto;}
	.opts_line .txt .label{min-width: 80px;display: inline-block;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	.el-icon-document-copy{cursor: pointer;}
	
	.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
	/*red border color*/
	.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
</style>
